export const FR = {
  Account: {
    login: 'Connexion',
    logout: 'Déconnexion',
  },
  DisplayOptions: {
    display: 'Affichage',
    display_metric: 'Afficher le nom de la métrique',
    display_result_count: 'Afficher le nombre de résultat',
    in_column: 'En colonne',
    in_row: 'En ligne',
    local: 'Locale',
    timestamp: 'Horodatage',
    with: 'Avec',
    without: 'Sans',
  },
  FormulaParameters: {
    aggregations: 'Agrégations',
    choose_aggregations: 'Je veux choisir des agrégations',
    data_items: 'Item(s) de données',
    data_source: 'Source',
    end_date: 'Heure de fin',
    formula_type: 'Type de formule',
    frequency: 'Fréquence (optionnelle)',
    header: 'Paramètres de la formule',
    result_cell: 'Cellule du résultat',
    start_date: 'Heure de début',
    until_now: "Jusqu'à maintenant",
  },
  Help: {
    find_help: "Retrouver l'aide IndaSheet",
    here: 'ici',
  },
  SearchModal: {
    action: 'Action',
    no_tags: 'Aucun tag trouvé !',
    search_metadata: 'Rechercher par métadonnées',
    search_metric: 'Rechercher une métrique',
    search_tag: 'Rechercher par nom de tag',
    select_database: 'Selectionnez une base de données',
    source: 'Source',
    source_error: 'Merci de sélectionner une source',
    tag: 'Tag',
    tag_name: 'Nom du tag',
    unit: 'Unité',
  },
  Shared: {
    apply: 'Appliquer',
    cancel: 'Annuler',
    close: 'Fermer',
    no: 'Non',
    validate: 'Valider',
    yes: 'Oui',
  },
  TemplateImport: {
    error: {
      generic: 'Une erreur est survenue',
      too_large: "La table est trop grande pour être affichée",
    },
    metric: 'Métrique',
    metrics: 'Métriques',
    table_too_large: 'La table a été tronquée car il y a trop de résultats',
    timestamp: 'Horodatage',
    tree: 'Arborescence',
    type: 'Type',
    value: 'Valeur',
  },
};
