import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { DataStructure, IndametaItemMetric } from 'ngx-indasuite-artifacts';

@Component({
  selector: 'app-search-modal',
  templateUrl: './search-modal.component.html',
  styleUrls: ['./search-modal.component.scss'],
})
export class SearchModalComponent implements OnInit {
  selectedMetrics = new Array<IndametaItemMetric>();
  dataStructure: DataStructure;
  multiple = false;
  currentTab = 0;

  constructor(private route: ActivatedRoute) {}

  ngOnInit(): void {
    this.multiple = this.route.snapshot.queryParamMap.get('multiple') === 'true';
  }

  onSelectMetric(metric: IndametaItemMetric) {
    const arrayIncludes = this.selectedMetrics.findIndex(
      (element) => metric.name === element.name && metric.datasource === element.datasource
    );
    if (arrayIncludes >= 0) this.removeMetric(arrayIncludes);
    else if (this.multiple) this.selectedMetrics = [...this.selectedMetrics, metric];
    else this.selectedMetrics = [metric];
  }

  removeMetric(index: number): void {
    const newMetrics = [...this.selectedMetrics];
    newMetrics.splice(index, 1);
    this.selectedMetrics = newMetrics;
  }

  close(save: boolean): void {
    if (save) {
      const messageObject = {
        tags: this.selectedMetrics.map((metric) => ({
          tag_indaba: metric.name,
          api: metric.datasource,
        })),
      };

      Office.context.ui.messageParent(JSON.stringify(messageObject));
    } else {
      const messageObject = { messageType: 'dialogClosed' };
      const jsonMessage = JSON.stringify(messageObject);
      Office.context.ui.messageParent(jsonMessage);
    }
  }
}
