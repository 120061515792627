import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root',
})
export class IndabaService {
  constructor(private http: HttpClient) {}

  public getDatabases(): Observable<Array<string>> {
    return this.http
      .get<any>(
        `${environment.indabaUrl}databases`
      )
      .pipe(map((value: any) => value.data));
  }
}
