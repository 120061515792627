<mat-form-field [style.width.%]="100" appearance="fill" *ngIf="showBeginDate">
  <mat-label translate>FormulaParameters.start_date</mat-label>
  <input matInput [ngxMatDatetimePicker]="dps" (dateChange)="startDateChange($event)">
  <mat-datepicker-toggle matSuffix [for]="$any(dps)"></mat-datepicker-toggle>
  <ngx-mat-datetime-picker #dps [showSeconds]="true"></ngx-mat-datetime-picker>
</mat-form-field>

<mat-slide-toggle (change)="toggleUntilNow($event)" *ngIf="showEndDate" style="margin-left: 10%">{{ "FormulaParameters.until_now" | translate }}</mat-slide-toggle>
<mat-form-field [style.width.%]="100" appearance="fill" *ngIf="showEndDate">
  <mat-label translate>FormulaParameters.end_date</mat-label>
  <input matInput [ngxMatDatetimePicker]="dpe" (dateChange)="endDateChange($event)" [disabled]="untilNowChecked">
  <mat-datepicker-toggle matSuffix [for]="$any(dpe)"></mat-datepicker-toggle>
  <ngx-mat-datetime-picker #dpe [showSeconds]="true" [disabled]="untilNowChecked"></ngx-mat-datetime-picker>
</mat-form-field>
