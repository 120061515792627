import { Component } from '@angular/core';
import { ContextSettingService } from '../context-setting.service';

@Component({
  selector: 'app-search-bar',
  templateUrl: './search-bar.component.html',
  styleUrls: ['./search-bar.component.scss'],
})
export class SearchBarComponent {
  constructor(private contextSettingService: ContextSettingService) {}

  apply() {
    let tIndabaParam = this.contextSettingService.getSetting();

    Excel.run(function (context) {
      const range = context.workbook.getSelectedRange();

      range.values = [[tIndabaParam.tag_name || '']];

      return context.sync();
    });
  }
}
