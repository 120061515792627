import { Component, EventEmitter, OnDestroy, OnInit, Output } from '@angular/core';
import { ContextSettingService } from '../context-setting.service';
import { IndabaService } from '../service/indaba.service';
import { SessionDataService } from '../service/sessiondata.service';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-data-source',
  templateUrl: './data-source.component.html',
  styleUrls: ['./data-source.component.css']
})
export class DataSourceComponent implements OnInit, OnDestroy {
  public listDataSources!: Array<string>;
  public selectedDataSource!: string;
  
  @Output() selectionChange: EventEmitter<string> = new EventEmitter<string>();
  
  private lastSourceChangedSubscription: Subscription;
  
  constructor(
    private indabaService: IndabaService,
    private sessionData: SessionDataService,
    private contextSettingService: ContextSettingService
  ) {}

  ngOnInit(): void {
    this.indabaService.getDatabases().subscribe((groups) => {
      this.listDataSources = groups;

      if (this.listDataSources?.length > 0) {
        const lastSource = this.sessionData.getLastSource();
        if (lastSource)
          this.selectedDataSource = groups.filter((grp) => grp === lastSource)[0];
        else if (!this.selectedDataSource) this.selectedDataSource = groups[0];
      }
      this.dataSourceChange();
    });
    // subscribe to the last source change
    this.lastSourceChangedSubscription = this.sessionData.lastSourceChanged.subscribe((lastSource) => {
      if(!lastSource || this.selectedDataSource === lastSource) return;
      this.selectedDataSource = lastSource;
      this.dataSourceChange();
    });
  }

  ngOnDestroy(): void {
    this.lastSourceChangedSubscription?.unsubscribe();
  }

  dataSourceChange() {
    this.selectionChange.emit(this.selectedDataSource);
    this.sessionData.setLastSource(this.selectedDataSource);
    this.contextSettingService.setSetting('base', this.selectedDataSource);
  }
}
