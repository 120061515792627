import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class SessionDataService {
  private readonly lastSourceKey = 'indabaLastSource';

  // observable to notify the change of the last source
  public lastSourceChanged = new Subject<string>();

  public setLastSource(lastSource: string) {
    window.sessionStorage.setItem(this.lastSourceKey, lastSource);
    this.lastSourceChanged.next(lastSource);
  }

  public getLastSource() {
    return window.sessionStorage.getItem(this.lastSourceKey);
  }
}
