import { RequestType } from 'src/shared/models/request-type';

export class FormulaBuilder {
  constructor(
    public datasource: string,
    public metric: string,
    public start: string,
    public end: string,
    public untilNow: boolean,
    public aggregation: string,
    public interval: string,
    public displayInRow: boolean,
    public displayTimestamp: boolean,
    public displayCount: boolean,
    public displayMetric: boolean,
    public displayLocalDate: boolean
  ) {}

  static fromSettings(tIndabaParam: Record<string, any>): FormulaBuilder {
    const datasource = `"${tIndabaParam.base || ''}"`;
    const metric = tIndabaParam.tag_name_cell ? tIndabaParam.tag_name : `"${tIndabaParam.tag_name || ''}"`;
    const start = `"${tIndabaParam.start_date || ''}"`;
    const end = `"${tIndabaParam.end_date || ''}"`;
    const untilNow = tIndabaParam.until_now ?? false;
    const aggregation = `"${tIndabaParam.aggregation || 'none'}"`;
    const interval = `"${tIndabaParam.interval || ''}"`;
    const displayInRow = tIndabaParam.displayInRow || false;
    const displayTimestamp = tIndabaParam.displayTimestamp || false;
    const displayCount = tIndabaParam.displayCount || false;
    const displayMetric = tIndabaParam.displayMetric || false;
    const displayLocalDate = tIndabaParam.displayLocalDate ?? true;
    return new FormulaBuilder(
      datasource,
      metric,
      start,
      end,
      untilNow,
      aggregation,
      interval,
      displayInRow,
      displayTimestamp,
      displayCount,
      displayMetric,
      displayLocalDate
    );
  }

  buildFormula(requestType: RequestType): string {
    let modeOptional = false;
    if (
      this.interval === '""' &&
      !this.displayInRow &&
      this.displayTimestamp &&
      this.displayLocalDate &&
      this.displayMetric &&
      !this.displayCount &&
      !this.untilNow
    )
      modeOptional = true;

    let formula = '';
    if (requestType === 'RANGE') {
      if (modeOptional)
        formula = `=iobase.IDB_RANGE(${this.datasource}, ${this.metric}, ${this.start}, ${this.end}, ${this.aggregation})`;
      else
        formula = `=iobase.IDB_RANGE(${this.datasource}, ${this.metric}, ${this.start}, ${this.end}, ${this.aggregation}, ${this.interval}, ${this.displayInRow}, ${this.displayTimestamp}, ${this.displayCount}, ${this.displayMetric}, , , ${this.displayLocalDate}, ${this.untilNow})`;
    } else if (requestType === 'INSTANT') {
      if (modeOptional) formula = `=iobase.IDB_INSTANT(${this.datasource}, ${this.metric}, ${this.start})`;
      else
        formula = `=iobase.IDB_INSTANT(${this.datasource}, ${this.metric}, ${this.start}, ${this.displayInRow}, ${this.displayTimestamp}, ${this.displayMetric}, ${this.displayLocalDate})`;
    } else {
      // Latest
      if (modeOptional) formula = `=iobase.IDB_LATEST(${this.datasource}, ${this.metric})`;
      else
        formula = `=iobase.IDB_LATEST(${this.datasource}, ${this.metric}, ${this.displayInRow}, ${this.displayTimestamp}, ${this.displayMetric}, ${this.displayLocalDate})`;
    }
    return formula;
  }
}
