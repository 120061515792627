import { Component, EventEmitter, Input, OnDestroy, OnInit, Output, ViewChild } from '@angular/core';
import { FormControl } from '@angular/forms';

import {
  DatabaseService,
  IndabaMeasurementsResponseModel,
  IndametaItemMetric,
  IndasuiteHashedPaginationComponent,
  PaginationModel,
  Tag,
} from 'ngx-indasuite-artifacts';
import { Observable, Subscription, of } from 'rxjs';
import { catchError, debounceTime, distinctUntilChanged, map, switchMap, tap } from 'rxjs/operators';

@Component({
  selector: 'app-search-tag-input',
  templateUrl: './search-tag-input.component.html',
  styleUrls: ['./search-tag-input.component.scss'],
})
export class SearchTagInputComponent implements OnInit, OnDestroy {
  @ViewChild('paginator') paginator: IndasuiteHashedPaginationComponent;

  @Input() multiple = false;

  @Input() selectedMetrics: IndametaItemMetric[];
  @Output() toggleSelectMetric = new EventEmitter<IndametaItemMetric>();

  public isLoading: boolean;

  public selectedSource: string;

  public searchTag: string = '';

  public listTag = new Array<Tag>();

  public displayedColumns = ['tag', 'unite', 'action'];

  public hasNoSource: boolean;
  public hasNoFoundTag: boolean;

  public pageSize = 5;

  tagFieldControl = new FormControl<string>(this.searchTag);
  tagFieldCtrlSub: Subscription;

  constructor(private databaseService: DatabaseService) {}

  ngOnInit() {
    this.tagFieldCtrlSub = this.tagFieldControl.valueChanges
      .pipe(
        debounceTime(500),
        distinctUntilChanged(),
        switchMap((filter) => this.search(filter as string))
      )
      .subscribe();
  }

  ngOnDestroy(): void {
    this.tagFieldCtrlSub.unsubscribe();
  }

  public tagIsSelected(element: Tag): boolean {
    return this.selectedMetrics?.length == 0
      ? false
      : !!this.selectedMetrics.find((metric: IndametaItemMetric) => metric.name === element.tag_indaba);
  }

  public search(filter: string): Observable<null> {
    this.hasNoSource = !this.selectedSource;
    this.hasNoFoundTag = false;

    this.searchTag = filter;
    this.paginator.init();

    return this.getMeasurements();
  }

  sourceSelected() {
    this.getMeasurements().subscribe();
  }

  pageSizeChange(size: number) {
    this.paginator.init();
    this.pageSize = size;
    this.getMeasurements().subscribe();
  }

  searchNext(nextPage: PaginationModel) {
    this.getMeasurements(nextPage).subscribe();
  }

  toggleTag(element: Tag): void {
    if (!element) return;

    this.toggleSelectMetric.emit({
      name: element.tag_indaba,
      datasource: element.api,
      unit: element.unite,
      id: `${element.api}@${element.tag_indaba}`,
    } as IndametaItemMetric);
  }

  private getMeasurements(nextPage?: PaginationModel): Observable<null> {
    this.isLoading = true;

    return this.databaseService.getMeasurements(this.selectedSource, this.searchTag, this.pageSize, nextPage).pipe(
      tap((response) => this.handleMeasurementsResponse(response)),
      catchError(() => this.handleErrorResponse()),
      map(() => null)
    );
  }

  private handleMeasurementsResponse(measurements: IndabaMeasurementsResponseModel): void {
    this.listTag = measurements.tags;
    this.hasNoFoundTag = !this.listTag?.length;
    this.paginator.addNextPage(measurements.pagination);
    this.isLoading = false;
  }

  private handleErrorResponse(): Observable<null> {
    this.isLoading = false;
    this.hasNoFoundTag = true;
    return of(null);
  }
}
