import { Component } from '@angular/core';
import { AuthService } from '@auth0/auth0-angular';

@Component({
  selector: 'app-account',
  templateUrl: './account.component.html',
  styleUrls: ['./account.component.scss']
})
export class AccountComponent {

  private dialog: any;

  constructor(public auth: AuthService) {}

  async logIn() {
    if (Office.context.platform === Office.PlatformType.OfficeOnline) {
      // In Excel online, the dialog window does not share the session with the task pane, so we need to use the Auth0 loginWithPopup method
      this.auth.loginWithPopup();
      return;
    } 
    
    Office.context.ui.displayDialogAsync(
      `${window.location.origin}/#/auth-form`,
      { height: 70, width: 70, displayInIframe: false },
      (asyncResult) => {
        if (asyncResult.status === Office.AsyncResultStatus.Failed) {
          // In addition to general system errors, there are 3 specific errors for
          // displayDialogAsync that you can handle individually.
          switch (asyncResult.error.code) {
            case 12004:
              console.log(
                'Failed to open login window',
                'Domain is not trusted'
              );
              break;
            case 12005:
              console.log('Failed to open login window', 'HTTPS is required');
              break;
            case 12007:
              console.log(
                'Failed to open login window',
                'A dialog is already opened.'
              );
              break;
            default:
              console.log(
                'Failed to open login window',
                asyncResult.error.message
              );
              break;
          }
        } else {
          this.dialog = asyncResult.value;

          this.dialog.addEventHandler(
            Office.EventType.DialogMessageReceived,
            (result: any) => {
              const message = JSON.parse(result.message);
              switch (message.messageType) {
                case 'Logged':
                  window.location.reload();
                  this.dialog.close();
                  this.dialog = null;
                  return;
              }
            }
          );
        }
      }
    );
  }

  async logOut() {
    if (Office.context.platform === Office.PlatformType.OfficeOnline) {
      // In Excel online, the dialog window does not share the session with the task pane, so we need to use the Auth0 logout method directly
      this.auth.logout({returnTo: `${window.location.origin}/#/account`});
      return;
    }

    Office.context.ui.displayDialogAsync(
      `${window.location.origin}/#/auth-logout`,
      { height: 70, width: 70, displayInIframe: false },
      (asyncResult) => {
        if (asyncResult.status === Office.AsyncResultStatus.Succeeded) {
          this.dialog = asyncResult.value;

          this.dialog.addEventHandler(
            Office.EventType.DialogMessageReceived,
            (result: any) => {
              const message = JSON.parse(result.message);
              switch (message.messageType) {
                case 'Logout':
                  window.location.reload();
                  this.dialog.close();
                  this.dialog = null;
                  return;
              }
            }
          );
        }
      }
    );
  }

}
