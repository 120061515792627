import { Component } from '@angular/core';
import { AuthService } from '@auth0/auth0-angular';

@Component({
  selector: 'app-auth-button',
  templateUrl: './auth-button.component.html',
  styles: [],
})
export class AuthButtonComponent {
  constructor(public auth: AuthService) {
    this.auth.isAuthenticated$.subscribe((logged) => this.isLogged(logged));
  }

  isLogged(logged: Boolean): void {
    if (logged) {
      let messageObject = { messageType: 'Logged' };
      let jsonMessage = JSON.stringify(messageObject);
      Office.context.ui.messageParent(jsonMessage);
    } else {
      this.auth.loginWithRedirect({
        appState: { target: `${window.location.origin}/#/auth-form` },
      });
    }
  }
}
