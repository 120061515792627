<h4 class="option-header" translate>DisplayOptions.display</h4>
<div class="row justify-content-center">
  <label id="slideAffichage" class="col-4 col-sm-3 text-center" translate>DisplayOptions.in_column</label>
  <div class="col-2 justify-content-center">
    <mat-slide-toggle
      aria-labelledby="slideAffichage"
      [(ngModel)]="displayInRow"
      (change)="setDisplayOnCol($event)"
    ></mat-slide-toggle>
  </div>
  <label id="slideAffichage" class="col-4 col-sm-3 text-center" translate>DisplayOptions.in_row</label>
</div>
<h4 class="option-header" translate>DisplayOptions.timestamp</h4>
<div class="row justify-content-center">
  <label id="slideTimestamp" class="col-4 col-sm-3 text-center" translate>DisplayOptions.without</label>
  <div class="col-2 justify-content-center">
    <mat-slide-toggle
      aria-labelledby="slideTimestamp"
      [(ngModel)]="displayTimestamp"
      (change)="setDisplayTimestamp($event)"
    ></mat-slide-toggle>
  </div>
  <label id="slideTimestamp" class="col-4 col-sm-3 text-center" translate>DisplayOptions.with</label>
</div>
<div *ngIf="displayTimestamp" class="row justify-content-center">
  <label id="slideLocalDate" class="col-4 col-sm-3 text-center">ISO</label>
  <div class="col-2 justify-content-center">
    <mat-slide-toggle
      aria-labelledby="slideLocalDate"
      [(ngModel)]="displayLocalDate"
      (change)="setDisplayLocalDate($event)"
    ></mat-slide-toggle>
  </div>
  <label id="slideLocalDate" class="col-4 col-sm-3 text-center" translate>DisplayOptions.local</label>
</div>
<h4 class="option-header" translate>DisplayOptions.display_metric</h4>
<div class="row justify-content-center">
  <label id="slideTag" class="col-4 col-sm-3 text-center" translate>Shared.no</label>
  <div class="col-2 justify-content-center">
    <mat-slide-toggle
      aria-labelledby="slideTag"
      [(ngModel)]="displayMetric"
      (change)="setDisplayMetric($event)"
    ></mat-slide-toggle>
  </div>
  <label id="slideTag" class="col-4 col-sm-3 text-center" translate>Shared.yes</label>
</div>
<ng-container *ngIf="showDisplayCount">
  <h4 class="option-header" translate>DisplayOptions.display_result_count</h4>
  <div class="row justify-content-center">
    <label id="slideCount" class="col-4 col-sm-3 text-center" translate>Shared.no</label>
    <div class="col-2 justify-content-center">
      <mat-slide-toggle
        aria-labelledby="slideCount"
        [(ngModel)]="displayCount"
        (change)="setDisplayCount($event)"
      ></mat-slide-toggle>
    </div>
    <label id="slideCount" class="col-4 col-sm-3 text-center" translate>Shared.yes</label>
  </div>
</ng-container>
