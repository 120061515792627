<app-cell-result></app-cell-result>

<h4 style="margin-left: 10%" translate>FormulaParameters.header</h4>
<mat-form-field [style.width.%]="100"
                appearance="fill">
  <mat-label translate>FormulaParameters.formula_type</mat-label>
  <mat-select [(value)]="requestType">
    <mat-option *ngFor="let type of requestTypes"
                [value]="type">
      {{type}}
    </mat-option>
  </mat-select>
</mat-form-field>

<app-data-source></app-data-source>
<app-data-item [multiple]="requestType !== 'RANGE'"></app-data-item>

<app-date-time-picker [showBeginDate]="requestType !== 'LATEST'"
                      [showEndDate]="requestType === 'RANGE'">
</app-date-time-picker>

<app-aggregate-options *ngIf="requestType === 'RANGE'"></app-aggregate-options>
<app-display-options [showDisplayCount]="requestType === 'RANGE'"></app-display-options>
<app-actions-group [requestType]="requestType"></app-actions-group>
