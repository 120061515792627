import { Component, Input, OnChanges } from '@angular/core';
import { ContextSettingService } from '../context-setting.service';
import { Tag } from 'ngx-indasuite-artifacts';
import { SessionDataService } from '../service/sessiondata.service';

@Component({
  selector: 'app-data-item',
  templateUrl: './data-item.component.html',
  styleUrls: ['./data-item.component.css'],
})
export class DataItemComponent implements OnChanges {
  private dialog: Office.Dialog;
  private form: any;

  @Input() multiple = false;

  dataItemValue?: string;

  constructor(private contextSettingService: ContextSettingService, private sessionData: SessionDataService) { }

  ngOnChanges() {
    if (this.multiple === false) {
      this.dataItemValue = '';
      this.setDataItem();
    }
  }

  async getFromCell() {
    await Excel.run(async (context) => {
      const cell = context.workbook.getActiveCell();
      cell.load('address');
      await context.sync();
      this.dataItemValue = cell.address;
      this.contextSettingService.setSetting('tag_name', this.dataItemValue);
      this.contextSettingService.setSetting('tag_name_cell', true);
    });
  }

  openSearchForm() {
    Office.context.ui.displayDialogAsync(
      `${window.location.origin}/#/search-form?multiple=${this.multiple}`,
      { height: 70, width: 70, displayInIframe: false },
      (asyncResult) => {
        if (asyncResult.status === Office.AsyncResultStatus.Failed) {
          // In addition to general system errors, there are 3 specific errors for
          // displayDialogAsync that you can handle individually.
          switch (asyncResult.error.code) {
            case 12004:
              console.log(
                'Failed to open login window',
                'Domain is not trusted'
              );
              break;
            case 12005:
              console.log('Failed to open login window', 'HTTPS is required');
              break;
            case 12007:
              console.log(
                'Failed to open login window',
                'A dialog is already opened.'
              );
              break;
            default:
              console.log(
                'Failed to open login window',
                asyncResult.error.message
              );
              break;
          }
        } else {
          this.form = this;
          this.dialog = asyncResult.value;
          this.dialog.addEventHandler(
            Office.EventType.DialogMessageReceived,
            (result: any) => {
              const message = JSON.parse(result.message);
              switch (message.messageType) {
                case 'dialogClosed':
                  this.dialog.close();
                  return;
                default:
                  this.form.dataItemValue = message.tags
                    .map(function (val: Tag) {
                      return val.tag_indaba;
                    })
                    .join(',');
                  this.form.contextSettingService.setSetting(
                    'tag_name',
                    this.form.dataItemValue
                  );
                  this.form.contextSettingService.setSetting('tag_name_cell', false);
                  // set the session data for the data source to the first tag api in the list
                  if (message.tags.length > 0) {
                    const dataSource = message.tags[0].api;
                    this.sessionData.setLastSource(dataSource);
                  }
                  this.dialog.close();
                  return;
              }
            }
          );
        }
      }
    );
  }

  setDataItem() {
    this.contextSettingService.setSetting('tag_name', this.dataItemValue);
    this.contextSettingService.setSetting('tag_name_cell', false);
  }
}
