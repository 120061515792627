<h1 mat-dialog-title translate>SearchModal.search_metric</h1>
<div mat-dialog-content class="pb-4">
  <div class="mt-2 mb-2">
    <mat-chip-list aria-label="Métriques sélectionnés">
      <mat-chip *ngFor="let metric of selectedMetrics; let i = index"
        class="chips"
        [disableRipple]="true"
        [selectable]="false"
        removable
                (removed)="removeMetric(i)">
        {{ metric?.name }}
        <img matChipRemove
          src="../../../../assets/icon/remove_circle_outline.svg"
          width="20px"
          height="20px"
          class="white-svg"
             alt="Supprimer" />
      </mat-chip>
    </mat-chip-list>
  </div>
  <mat-tab-group mat-align-tabs="center" [(selectedIndex)]="currentTab" (selectedIndexChange)="currentTab = $event">
    <mat-tab label="{{'SearchModal.search_metadata' | translate}}">
      <div class="h-100 d-flex flex-column">
        <ngx-indasuite-indameta-search
          [showActions]="true"
          [dataStructure]="dataStructure"
          [showDataStructures]="true"
          (toggleSelectMetric)="onSelectMetric($event)"
          [selectedMetrics]="selectedMetrics"
        >
        </ngx-indasuite-indameta-search>
      </div>
    </mat-tab>
    <mat-tab label="{{'SearchModal.search_tag' | translate}}">
      <app-search-tag-input
        [selectedMetrics]="selectedMetrics"
        (toggleSelectMetric)="onSelectMetric($event)"
        [multiple]="multiple"
      >
      </app-search-tag-input>
    </mat-tab>
  </mat-tab-group>
</div>
<div mat-dialog-actions
     class="j-end fixed-bottom mb-3 ml-3">
  <div>
    <button mat-raised-button
            color="warn"
            (click)="close(false)">
      {{ "Shared.cancel" | translate }}
    </button>
    <button mat-raised-button
            color="primary"
            class="ml-3"
            cdkFocusInitial
            (click)="close(true)">
      {{ "Shared.validate" | translate }}
    </button>
  </div>
</div>
