<ngx-indasuite-loading-spinner *ngIf="isLoading"></ngx-indasuite-loading-spinner>

<div class="d-flex flex-wrap filters a-center">
  <ngx-indasuite-database-select-list
    [(database)]="selectedSource"
    [label]="'SearchModal.source' | translate"
    (databaseChange)="sourceSelected()"
  >
  </ngx-indasuite-database-select-list>

  <mat-form-field appearance="outline" class="ml-2">
    <mat-label>
      {{ "SearchModal.tag_name" | translate }}
    </mat-label>
    <mat-icon matPrefix>search</mat-icon>
    <input type="text" matInput [formControl]="tagFieldControl" required />
  </mat-form-field>
</div>

<div class="mt-2 fushia" *ngIf="hasNoSource || hasNoFoundTag">
  <div *ngIf="hasNoSource" translate>SearchModal.source_error</div>

  <ngx-indasuite-empty-state *ngIf="hasNoFoundTag" icon="search" [text]="'SearchModal.no_tags' | translate">
  </ngx-indasuite-empty-state>
</div>

<div class="mt-2 w-100" [ngStyle]="{ display: listTag.length ? '' : 'none' }">
  <mat-table [dataSource]="listTag">
    <ng-container matColumnDef="action">
      <mat-header-cell *matHeaderCellDef>
        {{ "SearchModal.action" | translate }}
      </mat-header-cell>
      <mat-cell *matCellDef="let element" class="pointer">
        <button
          *ngIf="!tagIsSelected(element); else removeTag"
          mat-mini-fab
          color="primary"
          (click)="toggleTag(element)"
        >
          <mat-icon>add</mat-icon>
        </button>
        <ng-template #removeTag>
          <button mat-mini-fab color="warn" (click)="toggleTag(element)">
            <mat-icon>delete_forever</mat-icon>
          </button>
        </ng-template>
      </mat-cell>
    </ng-container>

    <ng-container matColumnDef="tag">
      <mat-header-cell *matHeaderCellDef>
        {{ "SearchModal.tag" | translate }}
      </mat-header-cell>
      <mat-cell *matCellDef="let element">
        {{ element.tag_indaba }}
      </mat-cell>
    </ng-container>

    <ng-container matColumnDef="unite">
      <mat-header-cell *matHeaderCellDef class="d-flex j-center">
        {{ "SearchModal.unit" | translate }}
      </mat-header-cell>
      <mat-cell mat-cell *matCellDef="let element">
        <div class="d-flex j-center w-100">
          <mat-form-field appearance="outline">
            <input type="text" [disabled]="true" matInput [(ngModel)]="element.unite" />
          </mat-form-field>
        </div>
      </mat-cell>
    </ng-container>

    <mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>
    <mat-row *matRowDef="let row; columns: displayedColumns"></mat-row>
  </mat-table>

  <ngx-indasuite-hashed-pagination
    [pageSize]="pageSize"
    (pageSizeChange)="pageSizeChange($event)"
    (nextPage)="searchNext($event)"
    #paginator
  >
  </ngx-indasuite-hashed-pagination>
</div>
