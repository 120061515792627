<app-data-item></app-data-item>
<div style="width: 100%; padding-top: 1em; text-align: center">
  <button
    mat-raised-button
    color="accent"
    style="width: 40%; margin-right: 1em"
    (click)="apply()"
  >
    {{ "Shared.apply" | translate }}
  </button>
</div>
