import { Component, Input, OnChanges, OnInit } from '@angular/core';
import { ContextSettingService } from '../context-setting.service';
import { MatSlideToggleChange } from '@angular/material/slide-toggle';

@Component({
  selector: 'app-display-options',
  templateUrl: './display-options.component.html',
  styleUrls: ['./display-options.component.css'],
})
export class DisplayOptionsComponent implements OnInit, OnChanges {
  @Input() showDisplayCount = true;

  public displayInRow: boolean = false;
  public displayTimestamp: boolean = true;
  public displayMetric: boolean = true;
  public displayCount: boolean = false;
  public displayLocalDate: boolean = true;

  constructor(private contextSettingService: ContextSettingService) {}

  ngOnInit(): void {
    this.contextSettingService.setSetting('displayInRow', this.displayInRow);
    this.contextSettingService.setSetting('displayTimestamp', this.displayTimestamp);
    this.contextSettingService.setSetting('displayMetric', this.displayMetric);
    this.contextSettingService.setSetting('displayCount', this.displayCount);
    this.contextSettingService.setSetting('displayLocalDate', this.displayLocalDate);
  }

  ngOnChanges(): void {
    this.displayCount = false;
    this.contextSettingService.setSetting('displayCount', this.displayCount);
  }

  setDisplayOnCol(aEvent: MatSlideToggleChange) {
    this.contextSettingService.setSetting('displayInRow', aEvent.checked);
  }

  setDisplayTimestamp(aEvent: MatSlideToggleChange) {
    this.contextSettingService.setSetting('displayTimestamp', aEvent.checked);
  }

  setDisplayMetric(aEvent: MatSlideToggleChange) {
    this.contextSettingService.setSetting('displayMetric', aEvent.checked);
  }

  setDisplayCount(aEvent: MatSlideToggleChange) {
    this.contextSettingService.setSetting('displayCount', aEvent.checked);
  }

  setDisplayLocalDate(aEvent: MatSlideToggleChange) {
    this.contextSettingService.setSetting('displayLocalDate', aEvent.checked);
  }
}
