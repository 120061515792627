import { Component } from '@angular/core';
import { RequestType, RequestTypeArray } from 'src/shared/models/request-type';

@Component({
  selector: 'app-main-form',
  templateUrl: './main-form.component.html',
  styleUrls: ['./main-form.component.scss'],
})
export class MainFormComponent {
  public requestTypes = RequestTypeArray;
  public requestType: RequestType = 'RANGE';

  constructor() {}
}
