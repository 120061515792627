import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AuthButtonComponent } from './auth-button/auth-button.component';
import { AuthLogoutComponent } from './auth-logout/auth-logout.component';
import { DefaultComponent } from './default/default.component';
import { HelpComponent } from './help/help.component';
import { MainFormComponent } from './main-form/main-form.component';
import { SearchBarComponent } from './search-bar/search-bar.component';
import { SearchModalComponent } from './search-modal/search-modal.component';
import { AccountComponent } from './account/account.component';
import { TemplateImportComponent } from './template-import/template-import.component';

const routes: Routes = [
  { path: 'auth-form', component: AuthButtonComponent },
  { path: 'auth-logout', component: AuthLogoutComponent },
  {
    path: '',
    component: DefaultComponent,
    children: [
      {
        path: 'main',
        component: MainFormComponent,
      },
      {
        path: 'help',
        component: HelpComponent,
      },
      {
        path: 'search-form',
        component: SearchModalComponent,
      },
      {
        path: 'search-bar',
        component: SearchBarComponent,
      },
      {
        path: 'account',
        component: AccountComponent
      },
      {
        path: 'template-import',
        component: TemplateImportComponent,
      },
      { path: '**', redirectTo: 'main' },
    ],
  },
  { path: '**', redirectTo: '' },
];

@NgModule({
  imports: [RouterModule.forRoot(routes, { onSameUrlNavigation: 'reload' })],
  exports: [RouterModule],
})
export class AppRoutingModule {}
