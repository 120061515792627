import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class ContextSettingService {
  tSettingObject: any;

  constructor() {
    this.tSettingObject = {};
  }

  setSetting(aProperty: string, aValue: any) {
    let tContextSettingValue: string =
      Office.context.document.settings.get('Indaba_Param');
    this.tSettingObject =
      tContextSettingValue === null ? {} : JSON.parse(tContextSettingValue);
    if (!(aProperty in this.tSettingObject))
      this.tSettingObject[aProperty] = {};
    this.tSettingObject[aProperty] = aValue;
    tContextSettingValue = JSON.stringify(this.tSettingObject);
    Office.context.document.settings.set('Indaba_Param', tContextSettingValue);
  }

  getSetting(): Record<string, any> {
    let tContextSettingValue: string =
      Office.context.document.settings.get('Indaba_Param');
    return tContextSettingValue === null
      ? {}
      : JSON.parse(tContextSettingValue);
  }
}
