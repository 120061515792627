<mat-form-field [style.width.%]="100" appearance="fill">
  <mat-label translate>FormulaParameters.data_items</mat-label>
  <input matInput [(ngModel)]="dataItemValue" (change)="setDataItem()">
  <div matSuffix style="display:flex">
    <button mat-icon-button (click)="getFromCell()">
      <mat-icon>apps</mat-icon>
    </button>
    <button mat-icon-button (click)="openSearchForm()">
      <mat-icon>search</mat-icon>
    </button>
  </div>
</mat-form-field>
