export const EN = {
  Account: {
    login: 'Login',
    logout: 'Logout',
  },
  DisplayOptions: {
    display: 'Display',
    display_metric: 'Display metric name',
    display_result_count: 'Display result count',
    in_column: 'In column',
    in_row: 'In row',
    local: 'Local',
    timestamp: 'Timestamp',
    with: 'With',
    without: 'Without',
  },
  FormulaParameters: {
    aggregations: 'Aggregations',
    choose_aggregations: 'I want to use aggregations',
    data_items: 'Data Item(s)',
    data_source: 'Source',
    end_date: 'End time',
    formula_type: 'Formula type',
    frequency: 'Frequency (optional)',
    header: 'Formula Parameters',
    result_cell: 'Cell of the result',
    start_date: 'Start time',
    until_now: 'Until now',
  },
  Help: {
    find_help: 'Find the IndaSheet help',
    here: 'here',
  },
  SearchModal: {
    action: 'Action',
    no_tags: 'No tag found !',
    search_metadata: 'Search by metadata',
    search_metric: 'Search a metric',
    search_tag: 'Search by tag name',
    select_database: 'Select a database',
    source: 'Source',
    source_error: 'Please, select a source',
    tag: 'Tag',
    tag_name: 'Tag name',
    unit: 'Unit',
  },
  Shared: {
    apply: 'Apply',
    cancel: 'Cancel',
    close: 'Close',
    no: 'No',
    validate: 'Validate',
    yes: 'Yes',
  },
  TemplateImport: {
    error: {
      generic: 'An error occurred',
      too_large: 'The table is too large to be displayed',
    },
    metric: 'Metric',
    metrics: 'Metrics',
    table_too_large: 'The table has been truncated because there are too many results',
    timestamp: 'Timestamp',
    tree: 'Structure',
    type: 'Type',
    value: 'Value',
  },
};
