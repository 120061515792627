import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import moment from 'moment';
import {
  DataStructure,
  IndabaParam,
  IndabaService,
  IndametaItem,
  IndametaItemMetric,
  IndametaPaginatedResponse,
  TimeSeriesRange,
} from 'ngx-indasuite-artifacts';
import { expand, Observable, of, reduce, takeWhile } from 'rxjs';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root',
})
export class IobaseCustomService {
  constructor(private http: HttpClient, private indabaService: IndabaService) {}

  public searchItems(
    dataStructure: DataStructure,
    typeId: string,
    paginationKey?: string
  ): Observable<IndametaPaginatedResponse<IndametaItem>> {
    let qp = new HttpParams()
      .set('isMetric', true)
      .set('displayParents', true)
      .set('typeId', typeId)
      .set('limit', 50000);

    if (paginationKey) {
      qp = qp.set('paginationKey', paginationKey);
    }
    return this.http.get<IndametaPaginatedResponse<IndametaItem>>(
      `${environment.indametaV2Url}data-structures/${dataStructure.id}/items`,
      { params: qp }
    );
  }

  public getAllItems(dataStructure: DataStructure, typeId: string): Observable<IndametaItem[]> {
    return this.searchItems(dataStructure, typeId).pipe(
      expand((response) => {
        if (response.paginationInfo?.isComplete === false) {
          // If response is not complete, get the next page
          return this.searchItems(dataStructure, typeId, response.paginationInfo.paginationKey);
        }
        // If response is complete, return null to stop the expansion
        return of(null);
      }),
      takeWhile((response) => response !== null),
      reduce((acc, response) => (response ? acc.concat(response.data ?? []) : acc), [] as IndametaItem[])
    );
  }

  public getRange(
    metric: IndametaItemMetric,
    startDate: string,
    endDate: string,
    datasource: string
  ): Observable<TimeSeriesRange> {
    const params = {
      metric,
      start: startDate,
      end: endDate,
      aggregation: 'none',
      limit: 50000,
      showAnnotation: false,
    } as IndabaParam;
    return this.indabaService.getRange(params, datasource).pipe(
      expand((response) => {
        if (response.complete === false) {
          // If response is not complete, get the next page
          const lastDate = response.data[response.data.length - 1].timestamp;
          const lastDatePlusOne = moment(lastDate).add(1, 'ms').toISOString();
          return this.indabaService.getRange({ ...params, start: lastDatePlusOne }, datasource);
        }
        // If response is complete, return null to stop the expansion
        return of(null);
      }),
      takeWhile((response) => response !== null),
      reduce((acc, response) => (response ? this.mergeTimeSeriesRanges(acc, response) : acc), {} as TimeSeriesRange)
    );
  }

  private mergeTimeSeriesRanges(accumulator: TimeSeriesRange, newRange: TimeSeriesRange): TimeSeriesRange {
    return {
      ...accumulator,
      ...newRange,
      data: (accumulator.data ?? []).concat(newRange.data ?? []),
    } as TimeSeriesRange;
  }
}
