import config from "./environment.json";

export class EnvironmentData {
  oauth2ClientId: string;
  env: string;

  indabaUrl: string;
  indametaUrl: string;
  indametaV2Url: string;

  oauth2Domain: string;
  oauth2Audience: string;  
  scope: string;

  urlExcel: string;
}

export const environment: EnvironmentData = config
