import { Component } from '@angular/core';
import { ContextSettingService } from '../context-setting.service';
import { MatSlideToggleChange } from '@angular/material/slide-toggle';

@Component({
  selector: 'app-aggregate-options',
  templateUrl: './aggregate-options.component.html',
  styleUrls: ['./aggregate-options.component.css'],
})
export class AggregateOptionsComponent {
  constructor(private contextSettingService: ContextSettingService) { }

  useAggregation: boolean = !false;
  aggregationTypesValue: string = "";
  aggregationFrequencyValue?: string;

  toggleAggregationOptions(aEvent: MatSlideToggleChange) {
    this.useAggregation = !aEvent.checked;
    this.aggregationTypesValue = '';
    this.aggregationFrequencyValue = undefined;
    this.contextSettingService.setSetting('aggregation', this.aggregationTypesValue);
    this.contextSettingService.setSetting('interval', this.aggregationFrequencyValue);
  }

  setAggregationTypes() {
    this.contextSettingService.setSetting('aggregation', this.aggregationTypesValue);
  }

  setAggregationFrequency() {
    this.contextSettingService.setSetting('interval', this.aggregationFrequencyValue);
  }
}
