import { Component } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { EN } from 'src/shared/translations/en';
import { FR } from 'src/shared/translations/fr';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css'],
})
export class AppComponent {
  constructor(private translate: TranslateService) {
    translate.setTranslation('en', EN, true);
    translate.setTranslation('fr', FR, true);

    translate.use(this.getLocale());
  }

  getLocale() {
    // Extract lang from excel
    const locale = Office.context.displayLanguage;
    if (locale?.startsWith('fr')) return 'fr';
    if (locale?.startsWith('en')) return 'en';
    return locale ?? 'en';
  }
}
