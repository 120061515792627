import { Component } from '@angular/core';
import { ContextSettingService } from '../context-setting.service';

@Component({
  selector: 'app-cell-result',
  templateUrl: './cell-result.component.html',
  styleUrls: ['./cell-result.component.css'],
})
export class CellResultComponent {
  constructor(private contextSettingService: ContextSettingService) {}

  cellResultValue: string = '';

  async getFromCell() {
    await Excel.run(async (context) => {
      const cell = context.workbook.getActiveCell();
      cell.load("address");
      await context.sync();
      this.cellResultValue = cell.address;
      this.contextSettingService.setSetting('cellResult', this.cellResultValue);
    });
  }

  setDataItem() {
    this.contextSettingService.setSetting('cellResult', this.cellResultValue);
  }
}
