import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

import { CellResultComponent } from './cell-result/cell-result.component';
import { DataSourceComponent } from './data-source/data-source.component';
import { DataItemComponent } from './data-item/data-item.component';
import { DateTimePickerComponent } from './date-time-picker/date-time-picker.component';
import { AggregateOptionsComponent } from './aggregate-options/aggregate-options.component';
import { DisplayOptionsComponent } from './display-options/display-options.component';
import { ActionsGroupComponent } from './actions-group/actions-group.component';

import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { MatButtonModule } from '@angular/material/button';
import { MatSelectModule } from '@angular/material/select';
import { MatIconModule } from '@angular/material/icon';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { MatNativeDateModule } from '@angular/material/core';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import {
  NgxMatDatetimePickerModule,
  NgxMatNativeDateModule,
  NgxMatTimepickerModule,
} from '@angular-material-components/datetime-picker';
import { MainFormComponent } from './main-form/main-form.component';
import { HashLocationStrategy, LocationStrategy } from '@angular/common';
import {
  AuthHttpInterceptor,
  AuthModule
} from '@auth0/auth0-angular';
import { AuthButtonComponent } from './auth-button/auth-button.component';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { MatPaginatorModule } from '@angular/material/paginator';
import { MatTableModule } from '@angular/material/table';
import { SearchModalComponent } from './search-modal/search-modal.component';
import { MatChipsModule } from '@angular/material/chips';
import { HelpComponent } from './help/help.component';
import { SearchBarComponent } from './search-bar/search-bar.component';
import { environment } from 'src/environments/environment';
import { DefaultComponent } from './default/default.component';
import { AuthLogoutComponent } from './auth-logout/auth-logout.component';
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import { AccountComponent } from './account/account.component';
import { NgxIndasuiteApiServicesModule, NgxIndasuiteTranslationModule, NgxIndasuiteUiModule } from 'ngx-indasuite-artifacts';
import { TemplateImportComponent } from './template-import/template-import.component';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { MatTabsModule } from '@angular/material/tabs';
import { SearchTagInputComponent } from './search-tag-input/search-tag-input.component';

@NgModule({
  declarations: [
    AppComponent,
    CellResultComponent,
    DataSourceComponent,
    DataItemComponent,
    DateTimePickerComponent,
    AggregateOptionsComponent,
    DisplayOptionsComponent,
    ActionsGroupComponent,
    MainFormComponent,
    AuthButtonComponent,
    SearchModalComponent,
    HelpComponent,
    SearchBarComponent,
    DefaultComponent,
    AuthLogoutComponent,
    AccountComponent,
    TemplateImportComponent,
    SearchTagInputComponent,
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    FormsModule,
    ReactiveFormsModule,
    BrowserAnimationsModule,
    MatFormFieldModule,
    MatInputModule,
    MatButtonModule,
    MatSelectModule,
    MatIconModule,
    MatSlideToggleModule,
    MatNativeDateModule,
    MatDatepickerModule,
    NgxMatDatetimePickerModule,
    NgxMatNativeDateModule,
    NgxMatTimepickerModule,
    MatPaginatorModule,
    MatTableModule,
    MatProgressSpinnerModule,
    MatChipsModule,
    MatSnackBarModule,
    MatTabsModule,
    HttpClientModule,
    AuthModule.forRoot({
      domain: environment.oauth2Domain,
      clientId: environment.oauth2ClientId,
      audience: environment.oauth2Audience,
      scope: environment.scope,
      redirectUri: `${window.location.origin}/#/auth-form`,
      cacheLocation: 'localstorage',
      useRefreshTokens: true,
      errorPath: '/forbidden',
      httpInterceptor: {
        allowedList: [
          `${environment.indabaUrl}*`,
          `${environment.indametaUrl}*`,
          `${environment.indametaV2Url}*`,
        ],
      },
    }),
    NgxIndasuiteTranslationModule,
    NgxIndasuiteUiModule.forRoot({
      customerLogoUrl: '',
      applicationLogo:
        'https://common.ressources.indasuite.io-base.com/common/images/iobase_logo.png'
    }),
    NgxIndasuiteApiServicesModule.forRoot({
      IndabaBaseUrl: environment.indabaUrl,
      IndametaBaseUrl: environment.indametaUrl,
      IndametaV2BaseUrl: environment.indametaV2Url,
      IndaPortalBaseUrl: '',
      Auth0Domain: '',
      IndaAlertingBaseUrl: '',
      IndaFormulaBaseUrl: '',
      UMSBaseUrl: ''
    }),
    TranslateModule.forRoot({
      defaultLanguage: 'en',
      useDefaultLang: true,
    }),
  ],
  providers: [
    { provide: LocationStrategy, useClass: HashLocationStrategy },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: AuthHttpInterceptor,
      multi: true,
    },
    TranslateService
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}
