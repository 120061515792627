import { Component, Input } from '@angular/core';
import { ContextSettingService } from '../context-setting.service';
import { RequestType } from 'src/shared/models/request-type';
import { FormulaBuilder } from './formula-builder';

@Component({
  selector: 'app-actions-group',
  templateUrl: './actions-group.component.html',
  styleUrls: ['./actions-group.component.css'],
})
export class ActionsGroupComponent {

  @Input() requestType: RequestType;

  constructor(private contextSettingService: ContextSettingService) { }

  invokeIdb() {
    let tIndabaParam = this.contextSettingService.getSetting();
    if (!('cellResult' in tIndabaParam)) return;
    const requestType = this.requestType;

    Excel.run(function (context) {
      let sheet = context.workbook.worksheets.getActiveWorksheet();
      let range = sheet.getRange(tIndabaParam.cellResult);

      const formulas = FormulaBuilder.fromSettings(tIndabaParam).buildFormula(requestType);

      range.formulas = [
        [
          formulas
        ]
      ];

      range.format.autofitColumns();
      return context.sync();
    });
  }
}
