import { Component, OnDestroy, OnInit } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import {
  DataStructure,
  FormatTyped,
  IndametaItem,
  Indametav2Service,
  Metric,
  MetricTemplate,
  TimeSeriesRange,
  Type,
} from 'ngx-indasuite-artifacts';
import { IobaseCustomService } from '../service/iobase-custom.service';
import { from, map, mergeMap, Observable, of, Subject, takeUntil, toArray, zip } from 'rxjs';
import moment from 'moment';
import { TranslateService } from '@ngx-translate/core';
import { DateHelper } from 'src/shared/helpers/date.helper';
import { ContextSettingService } from '../context-setting.service';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ExcelConstants } from 'src/shared/constants/excel.constants';

interface SearchForm {
  structure: DataStructure | null;
  type: Type | null;
  metrics: MetricTemplateWithTypeId[] | null;
}

export type MetricTemplateWithTypeId = MetricTemplate & { metricTypeId: string };

type TemplateImportValue = string | number | Excel.CellValue;

export interface MetricDataWithParentLabel {
  parentLabel: string;
  timeSeriesRange: TimeSeriesRange;
}

@Component({
  selector: 'app-template-import',
  templateUrl: './template-import.component.html',
  styleUrls: ['./template-import.component.scss'],
})
export class TemplateImportComponent implements OnInit, OnDestroy {
  dataStructures: DataStructure[] = [];
  types: Type[] = [];
  metrics: MetricTemplate[] = [];
  isLoading = false;
  searchForm = this.fb.group<SearchForm>({
    structure: null,
    type: null,
    metrics: null,
  });

  destroy$ = new Subject<void>();

  constructor(
    private fb: FormBuilder,
    private indametaV2Service: Indametav2Service,
    private ioBaseCustomService: IobaseCustomService,
    private translate: TranslateService,
    private contextSettingService: ContextSettingService,
    private _snackBar: MatSnackBar
  ) {}

  ngOnInit(): void {
    this.searchForm.controls.structure.addValidators(Validators.required);
    this.searchForm.controls.type.addValidators(Validators.required);
    this.searchForm.controls.metrics.addValidators(Validators.required);

    this.indametaV2Service.getDataStructures().subscribe((data) => {
      this.dataStructures = data;
    });

    this.searchForm.controls.structure.valueChanges.pipe(takeUntil(this.destroy$)).subscribe((structure) => {
      this.handleStructureChange(structure);
    });

    this.searchForm.controls.type.valueChanges.pipe(takeUntil(this.destroy$)).subscribe((type) => {
      this.handleTypeChange(type);
    });
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }

  handleStructureChange(structure: DataStructure | null): void {
    if (structure) {
      this.indametaV2Service.getDataStructureTypes(structure).subscribe((types) => {
        this.types = types;
      });
    }
    this.searchForm.controls.type.reset();
    this.searchForm.controls.metrics.reset();
  }

  handleTypeChange(type: Type | null): void {
    if (type) {
      this.metrics = type.metricTemplates;
    }
    this.searchForm.controls.metrics.reset();
  }

  searchMetrics(): void {
    if (this.searchForm.invalid || this.isLoading) return;

    const searchFormValue = this.searchForm.getRawValue();

    const selectedMetricTemplates = searchFormValue.metrics as MetricTemplateWithTypeId[];
    const metricTypeId = selectedMetricTemplates[0].metricTypeId;

    // Get the start and end date from the context settings or use defaults
    const tIndabaParam = this.contextSettingService.getSetting();
    const startDate: string = tIndabaParam.start_date ?? moment().subtract(7, 'd').toISOString();
    const untilNow = tIndabaParam.until_now ?? false;
    const endDate: string = tIndabaParam.end_date && !untilNow ? tIndabaParam.end_date : moment().toISOString();

    // Get the display options from the context settings or use defaults
    const displayInRow = tIndabaParam.displayInRow ?? false;
    const displayTimestamp = tIndabaParam.displayTimestamp ?? true;
    const displayLocalDate = tIndabaParam.displayLocalDate ?? true;
    const displayMetric = tIndabaParam.displayMetric ?? true;

    this.isLoading = true;

    this.ioBaseCustomService
      .getAllItems(searchFormValue.structure as DataStructure, metricTypeId)
      .pipe(
        takeUntil(this.destroy$),
        map((items) => this.getItemsMatchingMetricTemplates(items, selectedMetricTemplates)),
        mergeMap((items) => this.getMetricsDataWithTimeRange(items, startDate, endDate)),
        toArray()
      )
      .subscribe((metricsData: MetricDataWithParentLabel[]) => {
        this.buildAndDisplayTable(metricsData, displayInRow, displayTimestamp, displayLocalDate, displayMetric);
      })
      .add(() => (this.isLoading = false));
  }

  private getMetricsDataWithTimeRange(
    items: IndametaItem[],
    startDate: string,
    endDate: string
  ): Observable<MetricDataWithParentLabel> {
    return from(items).pipe(
      mergeMap((item) =>
        // Combine the parent label of the item with the range query for the metric
        zip(
          this.findParentLabel(item),
          this.ioBaseCustomService.getRange(item.metric, startDate, endDate, item.metric.datasource),
          (parentLabel, timeSeriesRange) => ({ parentLabel, timeSeriesRange })
        )
      )
    );
  }

  private findParentLabel(item: IndametaItem): Observable<string> {
    const parentObjects: Record<string, FormatTyped>[] = Object.values(item.parents ?? []).flat(1);
    const itemParent = parentObjects.find((parent) => parent.id === item.parentId);

    return of((itemParent?.label ?? '') as string);
  }

  getItemsMatchingMetricTemplates(
    items: IndametaItem[],
    selectedMetricTemplates: MetricTemplateWithTypeId[]
  ): IndametaItem[] {
    return items.filter((item) => {
      const idToMatch = item.metric?.id ?? '';
      return selectedMetricTemplates.some((metric) => {
        // Assuming that the metricTemplate has a placeholder in the form of {id}, we replace it with a regex that matches any string
        const templateRegex = metric.metricTemplate.replace(/\{\w+\}/g, '.*');
        return new RegExp(templateRegex).test(idToMatch);
      });
    });
  }

  async buildAndDisplayTable(
    metricsData: MetricDataWithParentLabel[],
    displayInRow: boolean,
    displayTimestamp: boolean,
    displayLocalDate: boolean,
    displayMetric: boolean
  ): Promise<void> {
    let table: TemplateImportValue[][] = [];
    // Set the table headers
    const typeName = this.searchForm.controls.type.value!.name;
    const headers = [typeName];
    if (displayMetric) headers.push(this.translate.instant('TemplateImport.metric'));
    if (displayTimestamp) headers.push(this.translate.instant('TemplateImport.timestamp'));
    headers.push(this.translate.instant('TemplateImport.value'));
    table.unshift(headers);

    // For each metric, add all values to the table
    for (const metricData of metricsData) {
      for (const metric of metricData.timeSeriesRange.data) {
        table.push(this.buildRowData(metricData, metric, displayTimestamp, displayLocalDate, displayMetric));
      }
    }

    if (displayInRow) {
      table = this.transposeTable(table);
    }

    let columnLength = table[0].length;
    let rowLength = table.length;

    if (rowLength >= ExcelConstants.MAX_ROWS - 1 || columnLength >= ExcelConstants.MAX_COLUMNS - 1) {
      columnLength = Math.min(columnLength, ExcelConstants.MAX_COLUMNS - 1);
      rowLength = Math.min(rowLength, ExcelConstants.MAX_ROWS - 1);
      // Truncate the table if it is too large
      table = table.slice(0, rowLength);
      table = table.map((row) => row.slice(0, columnLength));

      // Display a warning message
      this._snackBar.open(this.translate.instant('TemplateImport.table_too_large'), undefined, {
        duration: 5000,
        verticalPosition: 'top',
      });
    }
    await Excel.run(async (context) => {
      if (table.length > 0) {
        const sheet = context.workbook.worksheets.getActiveWorksheet();

        // Clear the existing data
        if (displayInRow) {
          sheet.getRangeByIndexes(0, 0, rowLength, ExcelConstants.MAX_COLUMNS).clear(Excel.ClearApplyTo.contents);
        } else {
          sheet.getRangeByIndexes(0, 0, ExcelConstants.MAX_ROWS, columnLength).clear(Excel.ClearApplyTo.contents);
        }
        await context.sync().catch((error) => this.handleError(error));

        // Update the range with the table values 10000 rows at a time to avoid exceeding the request payload size limit
        for (let i = 0; i < rowLength; i += 10000) {
          const subTable = table.slice(i, i + 10000);
          const subRange = sheet.getRangeByIndexes(i, 0, subTable.length, columnLength);
          this.setRangeValues(subRange, subTable);
          subRange.format.autofitColumns();
          await context.sync().catch((error) => this.handleError(error));
        }
      }
    });
  }

  private handleError(error: any) {
    let errorKey = 'TemplateImport.error.generic';
    if (error instanceof OfficeExtension.Error) {
      if (error.code === 'RequestPayloadSizeLimitExceeded') {
        errorKey = 'TemplateImport.error.too_large';
      }
    }
    // Display an error message
    this._snackBar.open(this.translate.instant(errorKey), this.translate.instant('Shared.close'), {
      verticalPosition: 'top',
      panelClass: 'snackbar-error',
    });
    throw new Error(error);
  }

  private setRangeValues(range: Excel.Range, table: TemplateImportValue[][]) {
    if (Office.context.requirements.isSetSupported('ExcelApi', '1.16')) {
      range.valuesAsJson = table as Excel.CellValue[][];
    } else {
      range.values = table;
    }
  }

  private buildRowData(
    metricData: MetricDataWithParentLabel,
    metric: Metric,
    displayTimestamp: boolean,
    displayLocalDate: boolean,
    displayMetric: boolean
  ): TemplateImportValue[] {
    const row: TemplateImportValue[] = [];
    let parentLabel: TemplateImportValue = metricData.parentLabel;
    let metricName: TemplateImportValue = metricData.timeSeriesRange.stream;
    let metricValue: TemplateImportValue = metric.value as number;

    if (Office.context.requirements.isSetSupported('ExcelApi', '1.16')) {
      parentLabel = { type: Excel.CellValueType.string, basicValue: parentLabel };
      metricName = { type: Excel.CellValueType.string, basicValue: metricName };
      metricValue = { type: Excel.CellValueType.double, basicValue: metricValue };
    }

    row.push(parentLabel);
    if (displayMetric) row.push(metricName);
    if (displayTimestamp) row.push(DateHelper.getFormattedDateForExcel(metric.timestamp, displayLocalDate));
    row.push(metricValue);
    return row;
  }

  private transposeTable(table: TemplateImportValue[][]): TemplateImportValue[][] {
    return table[0].map((_, colIndex) => table.map((row) => row[colIndex]));
  }
}
