import { Component } from '@angular/core';
import { AuthService } from '@auth0/auth0-angular';

@Component({
  selector: 'app-auth-logout',
  templateUrl: './auth-logout.component.html',
  styleUrls: ['./auth-logout.component.scss'],
})
export class AuthLogoutComponent {
  constructor(public auth: AuthService) {
    this.auth.isAuthenticated$.subscribe((logged) => this.isLogged(logged));
  }

  private isLogged(logged: Boolean): void {
    if (logged) {
      this.auth.logout({
        returnTo : `${window.location.origin}/#/auth-logout`
      });
    } else {
      let messageObject = { messageType: 'Logout' };
      let jsonMessage = JSON.stringify(messageObject);
      Office.context.ui.messageParent(jsonMessage);
    }
  }
}
