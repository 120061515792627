export class DateHelper {
  /**
   * Get the formatted date for Excel
   * @param isoDate
   * @returns formatted date for Excel, either a FormattedNumberCellValue or a number depending on the Excel version
   */
  static getFormattedDateForExcel(isoDate: string, displayLocalDate: boolean): Excel.FormattedNumberCellValue | number | string {
    if (!displayLocalDate) {
      return isoDate;
    }
    const date = new Date(isoDate);
    const excelEpoch = new Date(Date.UTC(1899, 11, 30)); // Excel epoch start date (December 30, 1899)
    const timeDifference = date.getTime() - date.getTimezoneOffset() * 1000 * 60 - excelEpoch.getTime();
    const excelDateNumber = timeDifference / (1000 * 60 * 60 * 24);

    if (Office.context.requirements.isSetSupported('ExcelApi', '1.16')) {
      return {
        type: Excel.CellValueType.formattedNumber,
        basicValue: excelDateNumber,
        numberFormat: 'yyyy-mm-dd hh:MM:ss',
      };
    } else {
      return excelDateNumber;
    }
  }
}
