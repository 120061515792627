<form [formGroup]="searchForm" (ngSubmit)="searchMetrics()">
  <mat-form-field appearance="fill" class="w-100">
    <mat-select formControlName="structure">
      <mat-option *ngFor="let struct of dataStructures" [value]="struct">
        {{ struct.name }}
      </mat-option>
    </mat-select>
    <mat-label translate>TemplateImport.tree</mat-label>
  </mat-form-field>
  <mat-form-field appearance="fill" class="w-100">
    <mat-select formControlName="type">
      <mat-option *ngFor="let type of types" [value]="type">
        {{ type.name }}
      </mat-option>
    </mat-select>
    <mat-label translate>TemplateImport.type</mat-label>
  </mat-form-field>
  <mat-form-field appearance="fill" class="w-100">
    <mat-select formControlName="metrics" multiple>
      <mat-option *ngFor="let metric of metrics" [value]="metric">
        {{ metric.label }}
      </mat-option>
    </mat-select>
    <mat-label translate>TemplateImport.metrics</mat-label>
  </mat-form-field>

  <app-date-time-picker [showBeginDate]="true" [showEndDate]="true"></app-date-time-picker>

  <app-display-options [showDisplayCount]="false"></app-display-options>

  <div class="d-flex justify-content-center flex-column align-items-center gap-2 mt-2">
    <button mat-raised-button [disabled]="searchForm.invalid" color="accent">
      <span translate>Shared.apply</span>
    </button>
    <mat-spinner *ngIf="isLoading" [diameter]="24"></mat-spinner>
  </div>
</form>
