import { Component, Input, OnInit } from '@angular/core';
import { ContextSettingService } from '../context-setting.service';
import { MAT_MOMENT_DATE_FORMATS, MomentDateAdapter } from '@angular/material-moment-adapter';
import { DateAdapter, MAT_DATE_FORMATS, MAT_DATE_LOCALE } from '@angular/material/core';
import * as _moment from 'moment';
// tslint:disable-next-line:no-duplicate-imports
import { default as _rollupMoment } from 'moment';
import { MatSlideToggleChange } from '@angular/material/slide-toggle';
import { MatDatetimePickerInputEvent } from '@angular-material-components/datetime-picker';
const moment = _rollupMoment || _moment;

@Component({
  selector: 'app-date-time-picker',
  templateUrl: './date-time-picker.component.html',
  styleUrls: ['./date-time-picker.component.css'],
  providers: [
    {
      provide: DateAdapter,
      useClass: MomentDateAdapter,
      deps: [MAT_DATE_LOCALE],
    },
    { provide: MAT_DATE_FORMATS, useValue: MAT_MOMENT_DATE_FORMATS },
  ],
})
export class DateTimePickerComponent implements OnInit {
  @Input() showBeginDate = true;
  @Input() showEndDate = true;

  untilNowChecked = false;

  constructor(private contextSettingService: ContextSettingService) {}

  ngOnInit(): void {
    this.contextSettingService.setSetting('until_now', this.untilNowChecked);
  }

  startDateChange(aEvent: MatDatetimePickerInputEvent<moment.Moment>) {
    this.contextSettingService.setSetting('start_date', aEvent.value ? this.toIsoDate(aEvent.value) : '');
  }

  endDateChange(aEvent: MatDatetimePickerInputEvent<moment.Moment>) {
    this.contextSettingService.setSetting('end_date', aEvent.value ? this.toIsoDate(aEvent.value) : '');
  }

  toggleUntilNow(aEvent: MatSlideToggleChange) {
    this.contextSettingService.setSetting('until_now', aEvent.checked);
    this.untilNowChecked = aEvent.checked;
  }

  toIsoDate(aDate: moment.Moment) {
    let tIsoDate: string = '';
    tIsoDate = aDate.toISOString();
    return `${tIsoDate.split('.')[0]}Z`;
  }
}
