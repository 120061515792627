<mat-slide-toggle style="margin-left: 10%" (change)="toggleAggregationOptions($event)">{{"FormulaParameters.choose_aggregations" | translate}}</mat-slide-toggle>
<mat-form-field [style.width.%]="100" appearance="fill">
  <mat-label translate>FormulaParameters.aggregations</mat-label>
  <mat-select  [(ngModel)]="aggregationTypesValue" #ctrl="ngModel" (selectionChange)="setAggregationTypes()" [disabled]="useAggregation">
    <mat-option value="MIN">MIN</mat-option>
    <mat-option value="MAX">MAX</mat-option>
    <mat-option value="SUM">SUM</mat-option>
    <mat-option value="AVG">AVG</mat-option>
    <mat-option value="COUNT">COUNT</mat-option>
  </mat-select>
</mat-form-field>
<mat-form-field [style.width.%]="100" appearance="fill">
  <mat-label translate>FormulaParameters.frequency</mat-label>
  <input matInput placeholder="ex: 1d, 1h, 1m"  [(ngModel)]="aggregationFrequencyValue" #ctrl="ngModel" (change)="setAggregationFrequency()" [disabled]="useAggregation">
</mat-form-field>
